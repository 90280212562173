import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { AccountCenterThemeEvent, SgwtAccountCenter } from '@sgwt/sgwt-widgets-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clsx } from 'clsx';
import ApiServices from '../../services/api.service';
import { QUERY_KEYS } from '../../shared/constants';
import { useUserRights } from '../../shared/security';
import { ContractAssessmentList } from '../../types/Contract';

const AssessmentLink = () => {
  const { data, isLoading } = useQuery<ContractAssessmentList>([QUERY_KEYS.CONTRACTS_ASSESSMENT], () => {
    return ApiServices.contracts.getAssessments();
  });
  const counter = data?.items.length ?? -1;

  return (
    <li className="navbar-item">
      <NavLink to="/assessments" className="navbar-link" aria-current="page">
        <Trans i18nKey="menu.assessments" />
        {isLoading && <div className="spinner spinner-sm ms-2" />}
        {counter >= 0 && (
          <span className={clsx('badge rounded-pill ms-2', counter === 0 ? 'bg-success' : 'bg-info')}>{counter}</span>
        )}
      </NavLink>
    </li>
  );
};

export default function Header() {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const rights = useUserRights();

  const changeTheme = (evt: AccountCenterThemeEvent) => {
    window.loadSgBootstrap(evt.theme);
  };

  const { environment } = window.globalConfiguration;

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(`${lang}-${lang.toUpperCase()}`);
  };

  const changeNavigateAs = () => {
    // The user may have the permissions changed, so we need to resend all requests.
    // Delay a little to let the NavigationAs cleans the local information, so the X-Navigate-As-User header
    // will contain the correct information.
    setTimeout(() => {
      queryClient.invalidateQueries();
    }, 200);
  };

  return (
    <nav className="navbar border-bottom border-top">
      <div className="navbar-title">
        <Link to="/" className="navbar-title-link">
          <div className="navbar-logo">
            <img src="https://shared.sgmarkets.com/assets/images/socgen_logo_muted.svg" alt="Société Générale" />
          </div>
          <div className="navbar-title-divider" />
          <div className="navbar-service-name">
            Softwares
            <br />
            Catalogue
          </div>
        </Link>
        <button
          className={clsx('navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center', show && 'show')}
          onClick={() => setShow(!show)}
          type="button"
          data-bs-toggle="dropdown"
          data-bs-offset="0,4"
          aria-expanded={show}
        >
          <img src="https://shared.sgmarkets.com/assets/images/socgen_logo_muted.svg" alt="Société Générale" />
        </button>
        <ul
          className={clsx('navbar-menu-dropdown dropdown-menu w-100', show && 'show')}
          data-bs-popper={show ? 'static' : 'none'}
        >
          <li className="navbar-item">
            <NavLink to="/explore/contracts" className="navbar-link">
              <Trans i18nKey="menu.explore.purchases-acts" />
            </NavLink>
          </li>
          <li className="navbar-item">
            <NavLink to="/explore/softwares" className="navbar-link">
              <Trans i18nKey="menu.explore.softwares" />
            </NavLink>
          </li>
        </ul>
      </div>
      <div className="navbar-content">
        <ul className="navbar-navigation m-0">
          <li className="navbar-item">
            <NavLink to="/explore/contracts" className="navbar-link">
              <Trans i18nKey="menu.explore.purchases-acts" />
            </NavLink>
          </li>
          <li className="navbar-item">
            <NavLink to="/explore/softwares" className="navbar-link">
              <Trans i18nKey="menu.explore.softwares" />
            </NavLink>
          </li>
          {rights.assessments.accessToPage && <AssessmentLink />}

          {rights.roles.administrator && (
            <li className="navbar-item">
              <NavLink to="/admin" className="navbar-link" aria-current="page">
                <Trans i18nKey="menu.administration" />
              </NavLink>
            </li>
          )}
        </ul>
      </div>
      <div className="navbar-toolbar">
        <SgwtAccountCenter
          availableLanguages={['en', 'fr']}
          authentication="sg-connect"
          mode="sg-markets"
          themeSwitcher
          onThemeChanged={changeTheme}
          environment={environment === 'production' ? '' : environment}
          navigateAs={rights.roles.administrator}
          onNavigateAsSelectUser={changeNavigateAs}
          onStopNavigationAs={changeNavigateAs}
          onLanguageChanged={changeLanguage}
        />
      </div>
    </nav>
  );
}
